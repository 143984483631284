<template>
  <div class="column-flex">
    <div class="search-plate flex">
      <div class="flex-left">
        <div>
          <span>医院：</span>
          <a-select :value="searchParam.hospital" placeholder="请选择医院">
            <a-select-option v-for="(item, index) in listHospital" :key="index" :value="item.id"
              @click="changeHospital1">{{item.name}}</a-select-option>
          </a-select>
        </div>
        <div>
          <span>科室：</span>
          <a-select :value="searchParam.departmentIds" placeholder="请选择科室">
            <a-select-option v-for="(item, index) in listDepartment" :key="index"
              :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </div>
        <div>
          <span>评估人：</span>
          <a-input v-model:value="searchParam.nurseName" placeholder="请输入评估人姓名" maxlength="50" />
        </div>
        <div>
          <span>评估时间：</span>
          <a-range-picker v-model:value="searchParam.rangeTime" format="YYYY/MM/DD" />
        </div>
        <a-button @click="querySearch" type="primary">
          查询
        </a-button>
        <a-button @click="reset">
          重置
        </a-button>
      </div>
      <a-button @click="exportDetail(exam)" type="primary">
        导出
      </a-button>
    </div>
    <div class="container">
      <a-table :columns="columns" :row-key="(record) => record.uuid" :data-source="examList" :pagination="pagination"
        :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'patientId'">
            {{record?.baseInfo?.patientId}}
          </template>
          <template v-if="column.key === 'baseInfo'">
            <a v-if="record?.baseInfo?.losIcu"
              @click="showModal(record.baseInfo)">ICU住院{{record.baseInfo.losIcu}}天，体温{{record.baseInfo.temperature}}℃，呼吸频率{{record.baseInfo.respRateMean}}次/分钟......</a>
          </template>
          <template v-if="column.key === 'caseCreatedName'">
            {{maskedString(record?.case?.nurse?.nickname)}}
          </template>
          <template v-if="column.key === 'caseCreatedAt'">
            {{getTime(record?.case?.createdAt)}}
          </template>
          <template v-if="column.key === 'assessmentStatus'">
            {{record?.case?.status==0?'待评估':'完成评估'}}
          </template>
          <template v-if="column.key === 'isAdoptAiResult'">
            {{record?.isAdoptAiResult == true?'是':record?.isAdoptAiResult == false ? '否' : ''}}
          </template>
          <template v-if="column.key === 'createdName'">
            {{maskedString(record?.nurse?.nickname)}}
          </template>
          <template v-if="column.key === 'createdAt'">
            {{getTime(record?.createdAt)}}
          </template>
          <template v-if="column.key === 'failRate'">
            {{record?.failRate?(Math.round(record?.failRate * 100) / 100)+'%':''}}
          </template>
          <template v-if="column.key === 'remarks'">
            <div class="remarks" :title="record?.remarks">{{record?.remarks}}</div>
          </template>
        </template>
      </a-table>
    </div>
    <a-drawer :width="520" title="患者信息" :visible="visible" @close="handleOk" :closable="false" :mask="false">
      <template #extra>
        <close-outlined @click="handleOk" />
      </template>
      <a-form :model="detailInfo" :label-wrap="true" label-align='left'>
        <a-form-item label="患者ID" name="patientId">
          <div>
            <text>{{detailInfo.patientId}}</text>
          </div>
        </a-form-item>
        <a-form-item label="flow_rate" name="flowRateMean">
          <div>
            <text>{{detailInfo.flowRateMean}}</text>
            <span>平均值</span>
          </div>
        </a-form-item>
        <a-form-item label=" " name="flowRateStd">
          <div>
            <text>{{detailInfo.flowRateStd}}</text>
            <span>标准差</span>
          </div>
        </a-form-item>
        <a-form-item label="呼吸频率(次/分钟)" name="respRateMean">
          <div>
            <text>{{detailInfo.respRateMean}}</text>
            <span>平均值</span>
          </div>
        </a-form-item>
        <a-form-item label=" " name="respRateStd">
          <div>
            <text>{{detailInfo.respRateStd}}</text>
            <span>标准差</span>
          </div>
        </a-form-item>
        <a-form-item label="平均细胞血红蛋白(/pg)" name="hemoglobinMean">
          <div>
            <text>{{detailInfo.hemoglobinMean}}</text>
            <span>平均值</span>
          </div>
        </a-form-item>
        <a-form-item label=" " name="hemoglobinStd">
          <div>
            <text>{{detailInfo.hemoglobinStd}}</text>
            <span>标准差</span>
          </div>
        </a-form-item>
        <a-form-item label="尿量(mL)" name="uoMlkghr12hrMean">
          <div>
            <text>{{detailInfo.uoMlkghr12hrMean}}</text>
            <span>平均值</span>
          </div>
        </a-form-item>
        <a-form-item label=" " name="uoMlkghr12hrStd">
          <div>
            <text>{{detailInfo.uoMlkghr12hrStd}}</text>
            <span>标准差</span>
          </div>
        </a-form-item>
        <a-form-item label="动脉血氧分压与吸入氧浓度比值" name="pao2fio2ratioMean">
          <div>
            <text>{{detailInfo.pao2fio2ratioMean}}</text>
            <span>平均值</span>
          </div>
        </a-form-item>
        <a-form-item label=" " name="pao2fio2ratioStd">
          <div>
            <text>{{detailInfo.pao2fio2ratioStd}}</text>
            <span>标准差</span>
          </div>
        </a-form-item>
        <a-form-item label="ICU住院时间(天)" name="losIcu">
          <div> {{detailInfo.losIcu}} </div>
        </a-form-item>
        <a-form-item label="收缩压(mmHg)" name="sbp">
          <div> {{detailInfo.sbp}} </div>
        </a-form-item>
        <a-form-item label="舒张压(mmHg)" name="dbp">
          <div> {{detailInfo.dbp}} </div>
        </a-form-item>
        <a-form-item label="白细胞计数(10/L)" name="lc">
          <div> {{detailInfo.lc}} </div>
        </a-form-item>
        <a-form-item label="血糖(mmol/L)" name="bg">
          <div> {{detailInfo.bg}} </div>
        </a-form-item>
        <a-form-item label="体温(℃)" name="temperature">
          <div> {{detailInfo.temperature}} </div>
        </a-form-item>
        <a-form-item label="自主咳痰能力" name="selfCoughingAbility">
          <div> {{detailInfo.selfCoughingAbility}} </div>
          <span class="tip"> (1分为无能力; 5分为完全正常) </span>
        </a-form-item>
        <a-form-item label="吸痰频率" name="suctioningFrequency">
          <div> {{getSuctioningFrequency(detailInfo.suctioningFrequency)}} </div>
        </a-form-item>
        <a-form-item label="肌力评分" name="musclePower">
          <div :title="detailInfo.musclePower+'-'+getMusclePower(detailInfo.musclePower)">
            {{detailInfo.musclePower}}-{{getMusclePower(detailInfo.musclePower)}} </div>
        </a-form-item>
        <a-form-item label="判断的拔管结果" name="predictingExtubationResult">
          <div> {{getPredictingExtubationResult(detailInfo.predictingExtubationResult)}} </div>
        </a-form-item>
        <a-form-item label="备注信息" name="remarks">
          <div class="remark">
            {{detailInfo.remarks}}
          </div>
        </a-form-item>
      </a-form>
      <template #footer>
        <div class="drawer-footer">
          <span class="tips">温馨提示：尿量为检测窗口12小时；其他为检测窗口5小时。</span>
          <a-button type="primary" @click="handleOk">关闭</a-button>
        </div>
      </template>
    </a-drawer>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted } from 'vue'
  import { listCaseResult, exportCaseResult } from '@/api/caseResult'
  import { listDepartments } from '@/api/department'
  import { Modal } from 'ant-design-vue';
  import { CloseOutlined } from '@ant-design/icons-vue';

  import moment from 'moment';
  import dayjs from 'dayjs'
  import 'dayjs/locale/zh-cn';

  const columns = [
    {
      title: '患者ID',
      dataIndex: 'patientId',
      key: 'patientId'
    },
    {
      title: '患者信息',
      dataIndex: 'baseInfo',
      key: 'baseInfo'
    },
    {
      title: '录入人',
      dataIndex: 'caseCreatedName',
      key: 'caseCreatedName'
    },
    {
      title: '录入时间',
      dataIndex: 'caseCreatedAt',
      key: 'caseCreatedAt'
    },
    {
      title: '评估状态',
      dataIndex: 'assessmentStatus',
      key: 'assessmentStatus'
    },
    {
      title: '评估人',
      dataIndex: 'createdName',
      key: 'createdName'
    },
    {
      title: '评估时间',
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: '模型分析失败概率',
      dataIndex: 'failRate',
      key: 'failRate'
    },
    {
      title: '是否采纳模型结果',
      dataIndex: 'isAdoptAiResult',
      key: 'isAdoptAiResult'
    },
    {
      title: '不采纳原因',
      dataIndex: 'remarks',
      key: 'remarks',
    },
  ]

  const listHospital = ref([])
  const listDepartment = ref([])

  const searchParam = reactive({
    hospital: null,
    departmentIds: '',
    nurseName: '',
    rangeTime: []
  })
  const examList = ref([])
  const detailInfo = ref({})
  const actionValue = ref('')
  const visible = ref(false)

  function querySearch() {
    pagination.current = 1;
    getDataList()
  }

  const getTime = (time) => {
    if (time) {
      const convertedTime = new Date(time);
      convertedTime.setHours(convertedTime.getHours() + 8);
      const formattedTime = convertedTime.toLocaleString('zh-CN', { timeZone: 'UTC' });
      return formattedTime;
    } else {
      return ''
    }
  }


  // 拔管结果
  const getPredictingExtubationResult = (e) => {
    switch (e) {
      case 0:
        return '成功';
      case 1:
        return '失败';
      default:
        return '';
    }
  }

  // 吸痰频率
  const getSuctioningFrequency = (e) => {
    switch (e) {
      case 0:
        return '1次/2~3h及以上';
      case 1:
        return '1次/2h';
      case 2:
        return '1~2次/h ';
      case 3:
        return '3次以上/h';
      default:
        return '';
    }
  }

  // 肌力评分：0-5级
  const getMusclePower = (e) => {
    switch (e) {
      case 0:
        return '无可测知的肌肉收缩';
      case 1:
        return '轻微收缩，不引起关节活动';
      case 2:
        return '减重状态能做关节全范围运动';
      case 3:
        return '能抵抗重力做关节全范围运动，不能抵抗阻力';
      case 4:
        return '能抵抗重力及一定阻力做关节全范围运动';
      case 5:
        return '能抵抗重力及充分阻力做关节全范围运动';
      default:
        return '';
    }
  }

  const exportDetail = () => {
    let { nurseName, rangeTime, hospital, departmentIds } = searchParam
    let params = {
      nurseName,
      startTime: rangeTime.length > 0 ? dayjs(rangeTime[0]).startOf('day').valueOf() : '',
      endTime: rangeTime.length > 0 ? dayjs(rangeTime[1]).endOf('day').valueOf() : '',
      _page: 1,
      _pageSize: pagination.total,
    }
    exportCaseResult(params).then((res) => {
      const name = getHospitalName(hospital) + getDepartmentName(departmentIds) + '呼吸机撤机风险评估记录-' + getCurrentDay() + '.xls'
      const file = {
        url: res.data.url.replace(process.env.VUE_APP_FILE_API, ""),
        name,
      }
      download(file);
    })
  }

  function download(file) {
    var x = new XMLHttpRequest();
    x.open('GET', file.url, true);
    x.responseType = 'blob';
    x.onload = function (e) {
      var url = window.URL.createObjectURL(x.response);
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    x.send();
  }

  const getCurrentDay = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  function getHospitalName(hospitalId) {
    const result = listHospital.value.find(item => item.id === hospitalId)
    return result && result.name
  }

  function getDepartmentName(departmentId) {
    const result = listDepartment.value.find(item => item.id === departmentId)
    return result && result.name
  }

  const showModal = (showInfo) => {
    let record = JSON.parse(JSON.stringify(showInfo))
    record.uoMlkghr12hrMean = record.uoMlkghr12hrMean == -1 ? '' : record.uoMlkghr12hrMean
    record.uoMlkghr12hrStd = record.uoMlkghr12hrStd == -1 ? '' : record.uoMlkghr12hrStd
    record.hemoglobinMean = record.hemoglobinMean == -1 ? '' : record.hemoglobinMean
    record.hemoglobinStd = record.hemoglobinStd == -1 ? '' : record.hemoglobinStd
    record.pao2fio2ratioMean = record.pao2fio2ratioMean == -1 ? '' : record.pao2fio2ratioMean
    record.pao2fio2ratioStd = record.pao2fio2ratioStd == -1 ? '' : record.pao2fio2ratioStd
    record.flowRateMean = record.flowRateMean == -1 ? '' : record.flowRateMean
    record.flowRateStd = record.flowRateStd == -1 ? '' : record.flowRateStd

    detailInfo.value = record
    visible.value = true;
  };

  const handleOk = (e) => {
    visible.value = false;
  };

  const pagination = reactive({
    showLessItems: true,
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (total) => `共 ${total} 条`,
    current: 1,
    pageSize: 10,
    total: 0
  })

  function handleTableChange(p) {
    pagination.current = p.current
    pagination.pageSize = p.pageSize

    getDataList();
  }

  const loading = ref(false)

  function getDataList() {
    let { nurseName, rangeTime } = searchParam

    let params = {
      nurseName,
      startTime: rangeTime.length > 0 ? dayjs(rangeTime[0]).startOf('day').valueOf() : '',
      endTime: rangeTime.length > 0 ? dayjs(rangeTime[1]).endOf('day').valueOf() : '',
      _pageSize: pagination.pageSize,
      _page: pagination.current,
    }
    listCaseResult(params).then((res) => {
      res.results.forEach((item, index) => {
        item.baseInfo.respRateMean = roundToTwoDecimals(item.baseInfo.respRateMean)
        item.baseInfo.respRateStd = roundToTwoDecimals(item.baseInfo.respRateStd)
        item.baseInfo.uoMlkghr12hrMean = roundToTwoDecimals(item.baseInfo.uoMlkghr12hrMean)
        item.baseInfo.uoMlkghr12hrStd = roundToTwoDecimals(item.baseInfo.uoMlkghr12hrStd)
      })
      examList.value = res.results
      pagination.total = res.pagination.total
    })
  }

  function roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }

  function getDefaultInfo() {
    listHospital.value = []
    listDepartment.value = []

    listDepartments().then((res) => {
      searchParam.hospital = res.results[0].id
      searchParam.departmentIds = res.results[0].departments[0].id

      // 获取医院、科室下拉列表
      res.results.forEach(item => {
        listHospital.value.push({
          name: item.name,
          id: item.id
        })

        item.departments.forEach(dep => {
          listDepartment.value.push({
            name: dep.name,
            id: dep.id
          })
        })
      });
    })
  }

  function maskedString(str) {
    if (str) {
      if (str.length < 2) {
        return str;
      } else if (str.length == 2) {
        return str[0] + '*';
      } else {
        return str[0] + '*' + str[str.length - 1];
      }
    } else {
      return '';
    }
  }

  function reset() {
    searchParam.nurseName = '';
    searchParam.rangeTime = [];

    getDataList();
  }

  onMounted(() => {
    getDefaultInfo();
    getDataList();
  })
</script>
<style lang="less" scoped>
  .column-flex {
    display: flex;
    flex-flow: column nowrap;
    height: calc(100vh - 105px);
  }

  .container {
    flex: 1;
  }

  .search-plate {
    background: #fff;
    padding: 25px 30px 10px;
    margin-bottom: 15px;
    border-radius: 5px;

    .ant-btn {
      margin-left: 10px;
    }

    .ant-btn-primary {
      background: #4687FE;
    }

    :deep .ant-select {
      width: 200px !important;
      margin-right: 10px;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .flex-left {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    >div {
      display: flex;
      align-items: center;
      margin: 0 20px 15px 0;

      >span {
        text-wrap: nowrap;
      }
    }

    >.ant-btn {
      margin-bottom: 15px;
    }
  }

  .ant-form {
    display: flex;
    flex-flow: row wrap;

    :deep .ant-form-item {
      display: flex;
      flex-flow: column nowrap;
      margin: 0 35px 12px 0;
      height: 64px;

      &:nth-child(1){
        width: 100%;
      }

      .ant-input {
        border-color: #EBEDF0;
      }
    }
  }

  :deep .ant-form-item-label>label {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.85);
  }

  .drawer-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tips {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 12px;
      color: #EC1818;
    }
  }

  :deep .ant-form-item-control-input-content {
    >div {
      width: 210px;
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      border: 1px solid #E7E7E7;
      border-radius: 2px;
      padding: 0 10px;
      color: #7E7F88;
      white-space: nowrap;
      overflow: hidden;
    }

    .remark {
      white-space: normal;
      padding: 6px 10px;
    }
  }

  .tip {
    position: absolute;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #F12929;
  }

  .remarks {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>